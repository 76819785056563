<template>
  <!-- 盖在主图上方的售罄组件 -->
  <!-- 单列商卡使用 -->
  <div 
    class="sold-out-container"
    :class="{
      'click-through': isClickThrough
    }" 
  >
    <div class="sold-out-content">
      <i class="sh_pc_sui_icon sh_pc_sui_icon_soldout_36px_fff"></i>
      <p class="sold-out-text">
        {{ saleout.message }}
      </p>
      <slot name="soldOutContent"></slot>
    </div>
  </div>
</template>

<script name="ProductCardImgSoldout" setup>
import { toRefs } from 'vue'
const props = defineProps({
  saleout: {
    type: Object,
    default() {
      return {}
    }
  },
  isClickThrough: {
    type: Boolean,
    default: false
  }
})

const { saleout } = toRefs(props)

</script>


<style lang="less" scoped>
.sold-out-container {
  position: absolute;
  left: 0;
  top: 0;
  z-index: @zindex-out;

  width: 100%;
  height: 100%;

  background: var(---sui_color_black_alpha30, rgba(0, 0, 0, 0.30));

  display: flex;
  justify-content: center;
  align-items: center;
  

  .sold-out-content  {
    text-align: center;
    pointer-events: auto;
  }

  .sold-out-text {
    font-weight: bold;
    font-size: 12px;
    color: #fff;
  }
}
.click-through {
  pointer-events: none;
}
</style>
